import React from "react"
import NotFoundPage from "../components/404Page/404Page"
import Seo from "../components/Shared/SEO/Seo";


const seoContent = {
  title: "Página no encontrada",
  description:
    "",
  url: ``,
  article: false,
};

const Page = () => {
  return (
    <>
      <Seo content={seoContent} />
      <NotFoundPage />
    </>
  )
}

export default Page
